import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from "styled-components";

import { Container, Wrapper, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const AgreementContent = styled.div`
  ol {
    list-style-type: upper-alpha;
    margin: 0.5rem 0 3rem 3.5rem !important;
  }

  ol ol {
    list-style-type: lower-roman;
    margin-bottom: 1.5rem !important;
  }

  ul {
    list-style-type: none;
    margin: 0.5rem 0 3rem 0rem !important;
    padding-left: 0;

    > li {
      margin-bottom: 25px;
    }
  }
`;

// export const query = graphql`
//   query {
//     contentfulSubscriptionAgreement {
//       modifiedDate(formatString: "MMMM D, YYYY")
//       title
//       content {
//         raw
//       }
//       version
//     }
//   }
// `;

export const query = graphql`
  query {
    contentfulUserAgreement(type: { eq: "Subscription" }) {
      lastModified(formatString: "MMMM D, YYYY")
      title
      content {
        raw
      }
      version
    }
  }
`;

const SubscriptionAgreement = (props) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (props.location.search.substr(1) === "reload") {
        window.history.pushState(
          {},
          null,
          props.location.origin + props.location.pathname
        );
        window.location.reload(true);
      }
    }
  }, [props.location.origin, props.location.pathname, props.location.search]);

  const website_url = process.env.GATSBY_SITE_URL;

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        if (
          node.data.uri.startsWith(website_url) ||
          node.data.uri.startsWith("/") ||
          node.data.uri.startsWith("#")
        ) {
          return (
            <Link to={node.data.uri}>
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </Link>
          );
        } else {
          return (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </a>
          );
        }
      },
    },
  };

  return (
    <Layout>
      <Seo
        title="Subscription Agreement | FlexiTime"
        description="FlexiTime Subscription Agreement."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80} maxWidth={1000}>
          <h1 className="-textCenter">
            {props.data.contentfulUserAgreement.title}
          </h1>
          <AgreementContent className="font-bump">
            {renderRichText(
              props.data.contentfulUserAgreement.content,
              options
            )}
          </AgreementContent>
          <Box stackGap={20}>
            <h6>
              Version: <b>{props.data.contentfulUserAgreement.version}</b>
            </h6>
            <h6>
              Last update:{" "}
              <b>{props.data.contentfulUserAgreement.lastModified}</b>
            </h6>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default SubscriptionAgreement;
